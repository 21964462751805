import React, { useEffect, useState } from "react";
import classes from '../../css/Pages/AccountPage.css'
import { useLocation, useNavigate } from "react-router-dom";
import { axiosAsk, getCookie } from "../../api/api";

import AccHeader from "../Parts/AccHeader";
import AccButItem from "../Parts/StackItems/AccButItem";
import MainBut from "../UI/MainBut/MainBut";
import ErrorPage from "./ErrorPage";
import PhoneInput from "../Parts/PhoneInput";
import { OkAlert } from "../UI/Alert/Alert";

function AccountPage(){
    const[error, setError] = useState(false)
    const[alertText, setAlertText] = useState('');

    const[accData, setAccData] = useState({name:'', surname:'',  mail:''})
    const[phone, setPhone] = useState('+7(')

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramValue = params.get('do');
    const navigate = useNavigate();


    useEffect(() => {
        if(paramValue !== 'edit'){
            return
        }

        axiosAsk({oper:'get_account_info_by_id', chat_id:getCookie('user_id'), flag:true})
        .then(response => {
            setAccData({name:response.data['name'], surname:response.data['surname'], mail:response.data['address']})
            setPhone(response.data['phone'] != '' ?response.data['phone'] :'+7(')
        })
        .catch(error => {setError(true); console.error(error)})
    }, [paramValue])

    useEffect(() => {
        if(getCookie('enter') === 'login'){

        }else{
            navigate('/login?do=logout')
        }
    }, [])


    function Exit(){
        navigate('/')
        document.cookie = 'enter=logout; path=/; max-age=157680000'
    }
    function SaveAccInfo(){
        axiosAsk({
            oper:'set_account_info_by_id',
            chat_id:getCookie('user_id'),
            name:accData.name,
            surname:accData.surname,
            phone:phone,
            address:accData.mail,
            flag:true
        })
        .then(() => {
            navigate('/profile')
            setAlertText('Изменения сохранены')
        })
        .catch(response => {setError(true); console.error(response)})
    }




    if(error){
        return(
            <ErrorPage/>
        )
    }

    if(paramValue === 'edit'){
        return(
            <div className='account edit_var'>
                <AccHeader to={'/profile'} backPermit={true}>РЕДАКТИРОВАТЬ ПРОФИЛЬ</AccHeader>
                <div className="edit_small_part">
                    <input
                        type="text"
                        className="first_inp"
                        id="acc_input"
                        placeholder="Имя"
                        value={accData.name}
                        onChange={(e) => setAccData({...accData, name:e.target.value})}
                    />
                    <input
                        type="text"
                        id="acc_input"
                        placeholder="Фамилия"
                        value={accData.surname}
                        onChange={(e) => setAccData({...accData, surname:e.target.value})}
                    />
                    <PhoneInput phone={phone} setPhone={setPhone}/>
                    <input
                        type="text"
                        className="last_inp"
                        id="acc_input"
                        placeholder="Электронна почта"
                        value={accData.mail}
                        onChange={(e) => setAccData({...accData, mail:e.target.value})}
                    />
                    <MainBut id='acc_edit_but' onClick={() => SaveAccInfo()} theme={'dark'}>СОХРАНИТЬ</MainBut>
                </div>
            </div>
        )
    }else{
        return(
            <div className="account">
                <OkAlert setNull={setAlertText}>{alertText}</OkAlert>
                <AccHeader backPermit={true}>ЛИЧНЫЙ КАБИНЕТ</AccHeader>
                <AccButItem to={'/profile?do=edit'} id='to_edit'>Редактировать профиль</AccButItem>
                <AccButItem to={'/cart'} id='to_cart'>Моя корзина</AccButItem>
                <AccButItem to={'/favorites'} id='to_favor'>Мои избранные</AccButItem>
                <AccButItem to={'/'} onClick={() => Exit()} id='to_exit'>Выйти</AccButItem>
            </div>
        )
    }
}

export default AccountPage; 