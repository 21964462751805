import React, { useEffect, useState } from "react";
import classes from './TextBut.module.css';

const TextBut = ({children, mrgnTp = '0px', size = '13px', ...props}) => {
    const[name, setName] = useState(Math.random().toString(36).substring(2, 4))

    useEffect(() => {
        document.getElementById(name).style.fontSize = size
    }, [size])
    useEffect(() => {
        document.getElementById(name).style.marginTop = mrgnTp
    }, [mrgnTp])

    return(
        <div {...props} id={name} className={classes.text_but}>{children}</div>
    )
}

export default TextBut;