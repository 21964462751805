import React, { useEffect, useRef, useState } from "react";
import { axiosAsk, getCookie } from "../../api/api";
import '../../css/Catalog.css';

import ProdCard from "./StackItems/ProdCard";
import InfiniteScroll from 'react-infinite-scroll-component';

const Catalog = ({setError, prodsIds, setWait, getScrollParent}) => {
    const[items, setItems] = useState([]);
    const[deletedItems, setDeletedItems] = useState([]);

    const[catalogPosition, setCatalogPosition] = useState(null);
    const[loadPermition, setLoadPermition] = useState(false);
    const[unloadPermition, setUnloadPermition] = useState(window.innerWidth);

    function getProductsBatch(ids){
        axiosAsk({
            oper:'get_item_info_by_id',
            ask_articles:ids,
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            setItems([...items, ...response.data['list']])
        })
        .catch(response => {setError(true); console.error(response)})
        .finally(() => {
            setWait(false)
            setLoadPermition(false)
        })
    }
    useEffect(() => {
        if(prodsIds != undefined && prodsIds.length != 0){
            if(items.length > 0){
                setItems([])
                setDeletedItems([])
                setCatalogPosition(-1)
            }else{
                setItems([])
                setDeletedItems([])
                setCatalogPosition(0)
            }
        }
    }, [prodsIds])
    function getNeededIdsArray(catalog_position){
        if(prodsIds.length === undefined || prodsIds.length === 0 || catalog_position < 0){
            return
        }

        if(catalog_position === 0){
            const ids = prodsIds.slice((0)*20, (prodsIds.length > 1*20 ?1*20 :prodsIds.length))
            getProductsBatch(ids)
        }else{
            const ids = prodsIds.slice(catalog_position*20, (prodsIds.length > (catalog_position+1)*20 ?(catalog_position+1)*20 :prodsIds.length))
            getProductsBatch(ids)
        }
    }
    useEffect(() => {
        if(loadPermition){
            setCatalogPosition(catalogPosition+1)
        }
    }, [loadPermition])
    useEffect(() => {
        if(catalogPosition == -1){
            setCatalogPosition(0)
        }else{
            getNeededIdsArray(catalogPosition)
        }
    }, [catalogPosition])

    // Логика для подгрузки следующих товаров
    const fetchMoreData = () => {
        if(document.getElementById('catalog') == undefined){
            return
        }

        if(document.getElementById('catalog').children[0] == undefined){
            return
        }

        const catalog = document.getElementById('catalog')
        if(catalog.children[catalog.children.length - 1].offsetTop - document.getElementById('scroll_wrapper').scrollTop <= 2000 && (items.length + deletedItems.length) < prodsIds.length){
            setWait(true)
            setLoadPermition(true)
        }
    };

    // Логика для удаления верхних элементов
    const handleScroll = () => {
        // console.log(document.getElementById('scroll_wrapper').scrollTop)
        if(document.getElementById('scroll_wrapper').scrollTop > 3500){
            setUnloadPermition(-1)
        }
    };

    // Восстановление удаленных элементов при прокрутке вверх
    const restoreDeletedItems = () => {
        if(document.getElementById('scroll_wrapper').scrollTop < 1700 && deletedItems.length >= 0){
            setUnloadPermition(1)
        }
    };

    useEffect(() => {
        if(unloadPermition === -1){
            AddNDellItems('down')
        }else if(unloadPermition === 1){
            AddNDellItems('up')
        }
    }, [unloadPermition])
    function AddNDellItems(direction){
        const currentScrollY = document.getElementById('scroll_wrapper').scrollTop;
        let RemoveProds = []
        let AddProds = []
        if(direction === 'up' && deletedItems.length > 0){
            // Восстановление удаленных товаров
            for(let i = deletedItems.length - 1; i >= 0; i--){
                if(AddProds.length < (window.innerWidth > 499 && window.innerWidth < 700 ?3 :4)){
                    AddProds.push(deletedItems[i]);
                } else {
                    RemoveProds.push(deletedItems[i]);
                }
            }
            AddProds.reverse()
            RemoveProds.reverse()
            const newAddProdsList = AddProds.filter(item => !items.some(existingItem => (existingItem.article[0] === item.article[0])));
            setItems(prevItems => [...newAddProdsList, ...prevItems]);
            setDeletedItems(RemoveProds);
        }else if(direction === 'down'){
            // Удаление верхних товаров
            for(let i = 0; i < items.length; i++){
                if(i < (window.innerWidth > 499 && window.innerWidth < 700 ?3 :4)){
                    RemoveProds.push(items[i]);
                } else {
                    AddProds.push(items[i]);
                }
            }
            const newDelProdsList = RemoveProds.filter(item => !deletedItems.some(existingItem => existingItem.article[0] === item.article[0]))
            setItems(AddProds);
            setDeletedItems(prevDeleted => [...prevDeleted, ...newDelProdsList]);
        }
        // window.scrollTo(0, currentScrollY-344);
        setUnloadPermition(0)
    }




    useEffect(() => {
        const scroll_wrapper = document.getElementById('scroll_wrapper')
        scroll_wrapper.addEventListener('scroll', handleScroll);
        scroll_wrapper.addEventListener('scroll', restoreDeletedItems);
        return () => {
            scroll_wrapper.removeEventListener('scroll', handleScroll);
            scroll_wrapper.removeEventListener('scroll', restoreDeletedItems);
        };
    }, [items, deletedItems]);



    return (
        // <div className="wrapper_3" id="wrapper_3" ref={scrollWrapRef} style={{height:'400px', overflow:'scroll'}}>
            <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                // items.length < prodsIds
                hasMore={true}
                getScrollParent={getScrollParent}
                scrollableTarget="scroll_wrapper"
            >
                <div className="catalog" id='catalog'>
                    {items.map((prod) => (
                        <div key={prod.article}>{<ProdCard info={prod} setError={setError}/>}</div>
                    ))}
                </div>
            </InfiniteScroll>
        // </div>
    );
};





export default Catalog;