import React, { useEffect, useState } from "react";
import classes from './CatalogFilterItem.module.css';

function CatalogFilterItem({theme = 'dark', imgsrc, groupeName, value, Checked, children, ...props}){
    const[name, setName] = useState(Math.random().toString(36).substring(2, 6))
    const[width, setWidth] = useState(0);

    function resetPosition(){
        document.getElementById(name).style.height = document.getElementById(name).offsetWidth-2 + 'px'
    }
    useEffect(() => {
        resetPosition()
    }, [width])
    useEffect(() => {
        function handleResize(){
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)

        return() => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return(
        <label>
            <input type='checkbox' name={groupeName} onClick={(e) => {Checked(e.currentTarget.checked, value, groupeName)}}/>
            <div id={name} className={classes.catalog_filter_item} {...props}>
                <div className={classes.text_part}>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                </div>
                <div className={classes.stroke_part}>
                    {/* <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div>
                    <div>{`${children} ${children} ${children} ${children}`}</div> */}
                </div>
                <div className={classes.image}>
                    <img src={imgsrc}/>
                </div>
            </div>
        </label>
    )
}

export default CatalogFilterItem;