import React, { useEffect, useState } from "react";
import classes from './MainBut.module.css';

const MainBut = ({children, theme, wait, right_num = 1, ...props}) => {

    return(
        <div {...props} className={(theme === 'dark') ?classes.main_but_dark :classes.main_but_light}>
            {wait === right_num
                ?<div className={classes.loader}></div>
                :children
            }
        </div>
    )
}

export default MainBut;