import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AccButItem({to, children, imgsrc, ...props}){

    return(
        <Link to={to}>
            <div className="acc_but_item" {...props}>
                <div className="img"></div>
                <div>{children}</div>
            </div>
        </Link>
    )
}

export default AccButItem;