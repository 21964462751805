import React, { useEffect, useState } from "react";
import { axiosAsk, getCookie } from "../../../api/api";

import H1 from "../../UI/H1/H1";
import MainBut from "../../UI/MainBut/MainBut";
import { useNavigate } from "react-router-dom";
import { WarningAlert } from "../../UI/Alert/Alert";

function InCartProd({info, size, index, price, addChecked, Delete, moreMode, count, setCount, setError, position = 'cart'}){
    const[availQuant, setAvailQuant] = useState(count);
    const[name, setName] = useState();
    const[checked, setChecked] = useState(false);
    const[alertText, setAlertText] = useState('');

    const navigate = useNavigate()



    useEffect(() => {
        if(info?.name === undefined){
            return
        }

        if(info?.name.length > 30){
            let i = 31
            while(info?.name[i] != ' '){
                --i
            }
            setName(info?.name.slice(0, i).padEnd(i+3, '.'))
        }else{
            setName(info?.name)
        }
    }, [info])
    useEffect(() => {
        if(document.getElementsByClassName('thumbnail')[index] === undefined || document.getElementsByClassName('thumbnail')[index] === null){
            return
        }


        if(moreMode){
            document.getElementsByClassName('thumbnail')[index].style.left = '30px'
        }else{
            document.getElementsByClassName('thumbnail')[index].style.left = '0'
            document.getElementsByClassName('info')[index].style.marginLeft = '5px'
            setChecked(false)
        }
        if(window.innerWidth > 450){
            if(moreMode){
                document.getElementsByClassName('info')[index].style.marginLeft = '35px'
            }else{
                document.getElementsByClassName('info')[index].style.marginLeft = '5px'
            }
        }
    }, [moreMode])
    useEffect(() => {
        if(count == undefined) return
        setAvailQuant(count)
    }, [count])


    function change(){
        if(moreMode){
            if(checked){
                setChecked(false)
                addChecked(info.article[0], size, true)
            }else{
                setChecked(true)
                addChecked(info.article[0], size)
            }
        }
    }


    function add(){
        axiosAsk({
            oper:'add_tov_in_busket',
            chat_id:getCookie('user_id'),
            article:info.article[0],
            size:size,
            flag:true
        })
        .then(response => {
            if(response.data['cnt'] != '' && response.data['cnt'] != 0){
                setCount(info.article[0], size, availQuant+1)
                setAvailQuant(availQuant+1)
            }else{
                setAlertText('Что-то пошло не так! Попробуйте ещё раз')
            }
        })
        .catch(error => {console.error(error); setError(true)})
    }
    function remove(){
        if(availQuant == 1){
            Delete([{size:size, article:info.article[0]}]);
            return
        }

        axiosAsk({
            oper:'remove_tov_from_busket',
            chat_id:getCookie('user_id'),
            article:info.article[0],
            size:size,
            flag:true
        })
        .then(response => {
            if(response.data['status'] === 'ok'){
                setCount(info.article[0], size, availQuant-1)
                setAvailQuant(availQuant-1)
            }else{
                setAlertText('Что-то пошло не так! Попробуйте ещё раз')
            }
        })
        .catch(error => {console.error(error); setError(true)})
    }
    // function fullRemove(){
    //     axiosAsk({
    //         oper:'delete_tov_from_busket',
    //         chat_id:getCookie('user_id'),
    //         list:[{size:size, article:info.article[0]}],
    //         flag:true
    //     })
    //     .then(response => {
    //         if(response.data['status'] === 'ok'){
    //             Delete([{size:size, article:info.article[0]}])
    //         }else{
    //             setAlertText('Что-то пошло не так! Попробуйте ещё раз')
    //         }
    //     })
    //     .catch(error => {console.error(error); setError(true)})
    // }

    return(
        <label>
            {alertText
                ?<WarningAlert setNull={setAlertText}>{alertText}</WarningAlert>
                :<></>
            }
            <input type="checkbox" id="prod_stat" onChange={change}/>
            <div className="in_cart_prod">
                {checked
                    ?<div className="tic active"></div>
                    :<div className="tic non_active"></div>
                }
                <div className="thumbnail">
                    <img src={`data:image/png;base64,${info?.photo}`}/>
                </div>
                <div className="info">
                    <div>
                        <div id="text_info" className="name" onClick={() => {!moreMode ?navigate(`/product?art=${encodeURIComponent(info.article)}`) :<></>}}>{name}</div>
                        <h1 id="text_info">{price + '₽'}</h1>
                        <div id="text_info">{'Размер: ' + size}</div>
                        {position !== 'cart'
                            ?<div id="text_info">{`Кол-во: ${count}`}</div>
                            :<></>
                        }
                    </div>
                    <div className="button_move_part">
                        {position === 'cart'
                            ?<div className="requant_part">
                                <MainBut theme={'dark'} onClick={() => remove()} id="minus_but"></MainBut>
                                <div className="num">{availQuant}</div>
                                <MainBut theme={'dark'} onClick={() => add()} id="plus_but"></MainBut>
                            </div>
                            :<></>
                        }
                        {!moreMode && position === 'cart'
                            ?<div className="but_line">
                                <MainBut theme={'dark'} onClick={() => navigate(`/checkout?avail=${encodeURI(info.article)};${encodeURI(size)}`)}>КУПИТЬ</MainBut>
                                <MainBut theme={'dark'} onClick={() => Delete([{size:size, article:info.article[0]}])}>УДАЛИТЬ</MainBut>
                            </div>
                            :<></>
                        }
                    </div>
                </div>
            </div>
        </label>
    )
}

export default InCartProd;