import React from "react";
import classes from './ReturnLeft.module.css';
import { useNavigate } from "react-router-dom";

function ReturnLeft({to = null, ...props}){
    const navigate = useNavigate();

    function nav(){
        if(to === null){
            navigate(-1)
        }else{
            navigate(to)
        }
    }

    return(
        <div className={classes.return_left_but} onClick={() => nav()} {...props}><div></div></div>
    )
}

export default ReturnLeft;