import React, { useEffect, useState } from "react";
import '../../css/Pages/CommentPage.css'
import { axiosAsk, getCookie } from "../../api/api";

import AccHeader from "../Parts/AccHeader";
import MainBut from "../UI/MainBut/MainBut";
import ErrorPage from "./ErrorPage";
import { useLocation } from "react-router-dom";
import Comment from "../Parts/StackItems/Comment";

function CommentPage(){
    const[error, setError] = useState(false);
    const[wait, setWait] = useState(0);
    const[mode, setMode] = useState('read');
    const[textError, setTextError] = useState('')
    const[productName, setProductName] = useState(null)

    const[comments, setComments] = useState([]);
    const[constScore, setConstScore] = useState(null);
    const[score, setScore] = useState(0);
    const[userComment, setUserComment] = useState('');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramValue = params.get('art');

    useEffect(() => {
        setWait(1)
        axiosAsk({oper:'get_comments', article:paramValue, flag:true})
        .then(response => {
            setComments(response.data)
            setWait(0)
        })
        .catch(error => {console.error(error); setError(true)})

        axiosAsk({oper:'get_item_info_by_id', ask_articles:[paramValue], flag:true, chat_id:getCookie('user_id')})
        .then(response => {
            let new_name = response.data.list[0].name
            if(new_name.length > 30){
                let i = 31
                while(new_name[i] != ' '){
                    --i
                }
                setProductName(new_name.slice(0, i).padEnd(i+3, '.'))
            }else{
                setProductName(new_name)
            }
            setWait(0)
        })
    }, [])
    useEffect(() => {
        let allScore = 0
        if(comments.length != 0){
            comments.forEach(comm => {
                allScore += comm.score
            })
            allScore = allScore/comments.length
            if(String(allScore).indexOf('.') === -1){
                allScore = String(allScore) + '.0'
            }
        }
        setConstScore(Number(allScore).toFixed(1))
    }, [comments])


    function addComment(){
        if(score === 0){
            setTextError('Поставьте оценку товару')
            return
        }
        if(userComment.length === 0){
            setTextError('Напишите короткий отзыв')
            return
        }

        setWait(1)
        axiosAsk({
            oper:'add_comment',
            chat_id:getCookie('user_id'),
            article:paramValue,
            comment:userComment,
            score:score,
            flag:true
        })
        .then(() => {
            setComments([{comment:userComment, date:'сейчас', score:score, tg:'Вы'}, ...comments])
            setMode('read')
            setUserComment('')
            setScore(0)
            setWait(0)
        })
        .catch(response => {setError(true); console.error(response)})
    }


    if(error){
        return(
            <ErrorPage/>
        )
    }

    if(mode === 'read'){
        return(
            <div className="comment_page">
                <AccHeader id='comm_header' backPermit={true}>ОТЗЫВЫ</AccHeader>
                <div className="stars_panel">
                    <div className="icon"></div>
                    <div className="score">{constScore}</div>
                    <div className="comments_num">{`${comments.length} отзывов(а)`}</div>
                    <MainBut id='comment_but' theme={'dark'} onClick={() => {mode == 'add' ?setMode('read') :setMode('add')}}>{mode == 'read' ?'Оценить' :'Отменить'}</MainBut>
                </div>
                <div className="comment_part" id="other_comms">
                    {wait === 1
                        ?<div className="wait_icon"></div>
                        :(comments.length
                            ?comments.map((item, index) => 
                                <Comment key={index} name={item.tg} date={item.date} score={item.score}>{item.comment}</Comment>
                            )
                            :<div className="no_comments">Пока отзывов нет</div>)
                    }
                </div>
            </div>
        )
    }else if(mode === 'add'){
        return(
            <div className="comment_page">
                <AccHeader id='comm_header'>
                    <div className="order_return_but" onClick={() => setMode('read')}></div>
                    <h1 className="order_header_h1">{(productName != null && productName != undefined) ?productName :'ОЦЕНИТЕ ТОВАР'}</h1>
                </AccHeader>
                <div className="add_area" id="user_comm">
                    <div className="star_area">
                        <div className={"star" + (score >= 1 ?' active' :'')} id="one" onClick={() => setScore(1)}></div>
                        <div className={"star" + (score >= 2 ?' active' :'')} id="two" onClick={() => setScore(2)}></div>
                        <div className={"star" + (score >= 3 ?' active' :'')} id="thr" onClick={() => setScore(3)}></div>
                        <div className={"star" + (score >= 4 ?' active' :'')} id="for" onClick={() => setScore(4)}></div>
                        <div className={"star" + (score >= 5 ?' active' :'')} id="fiv" onClick={() => setScore(5)}></div>
                    </div>
                    <div id="text_err">Ваш комментарий:</div>
                    <textarea className="user_text_area" value={userComment} onChange={(e) => setUserComment(e.target.value)}></textarea>
                    {textError !== ''
                        ?<h3>{textError}</h3>
                        :<></>
                    }
                    <MainBut id='comment_app_but' theme={'dark'} onClick={addComment} wait={wait} right_num={1}>Отправить</MainBut>
                </div>
            </div>
        )
    }
}

export default CommentPage;