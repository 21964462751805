import axios from "axios"
const SRVRADDRESS = 'https://kamazaz.store:443/users/'    //МОЙ:  'http://127.0.0.1:8000/users/'   СЕРВЕР:   http://5.188.119.217/users/

export const axiosAsk = (payload) => {
    return axios.post(SRVRADDRESS, payload)
}

export const fetchAsk = (payload) => {
    return fetch(SRVRADDRESS, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
}


export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}