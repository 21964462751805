import React, { useEffect } from "react";
import MainBut from "../UI/MainBut/MainBut";
import { Link } from "react-router-dom";

function Page404(){
    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
    }, [])

    return(
        <div className="page_404_part">
            <div className="smile_404"></div>
            <div className="text_part_404">
                <h1>ERROR 404</h1>
                <div className="mid_text_404">Страница не найдена</div>
                <Link to={'/'}><MainBut theme='dark'>Вернуться на главную</MainBut></Link>
            </div>
        </div>
    )
}

export default Page404;