import React, { useEffect, useState } from "react";

function PhoneInput({setPhone, phone}){
    const tamplate = '+7(___) ___-__-__'
    const[varInp, setVarInp] = useState('+7(___) ___-__-__')
    

    function handleResize(){
        let availWidth = document.getElementById('parent').style.widows
        document.getElementById('acc_input_lower').style.width = availWidth + 'px'
    }
    useEffect(() => {
        document.addEventListener('resize', handleResize)

        if(phone != undefined){
            setVarInp(phone)
        }

        return() => {
            document.removeEventListener('resize', handleResize)
        }
    }, [])



    function ChangePhone(val){
        if(val.length < 18){
            if(val > phone && /^\d+$/.test(val.slice(-1))){
                if(phone.length < 3){
                    setPhone('+7(' + val)
                    setVarInp('+7(' + val + tamplate.slice(4))
                }else if(phone.length === 6){
                    setPhone(phone + ') ' + val.slice(-1))
                    setVarInp(phone + ') ' + val.slice(-1) + tamplate.slice(9))
                }else if(phone.length === 11 || phone.length === 14){
                    setPhone(phone + '-' + val.slice(-1))
                    setVarInp(phone + '-' + val.slice(-1) + tamplate.slice(val.length+1))
                }else{
                    setPhone(val)
                    setVarInp(val + tamplate.slice(val.length))
                }
            }else if(val.length > 2 && val < phone){
                if(phone.length === 16 || phone.length === 13){
                    setPhone(val.slice(0, -1))
                    setVarInp(val.slice(0, -1) + tamplate.slice(val.length-1))
                }else if(phone.length === 9){
                    setPhone(val.slice(0, -2))
                    setVarInp(val.slice(0, -2) + tamplate.slice(val.length-2))
                }else{
                    setPhone(val)
                    setVarInp(val + tamplate.slice(val.length))
                }
            }
        }
    }
    return(
        <div className="phone_input" id="parent">
            <input
                type="text"
                id="acc_input_lower"
                value={phone}
                onChange={(e) => ChangePhone(e.target.value)}
            />
            <input
                type="text"
                id="acc_input_higer"
                value={varInp}
            />
    </div>
    )
}

export default PhoneInput;