import React, { useEffect, useState } from "react";

function Comment({children, name, date, score}){
    const[newDate, setNewDate] = useState(null);
    const[time, setTime] = useState(null);
    useEffect(() => {
        let now = new Date();
        let availDate = `${now.getFullYear()}-${String(now.getMonth()).length === 1 ?`0${now.getMonth()+1}` :now.getMonth()+1}-${String(now.getDate()).length === 1 ?`0${now.getDate()}` :now.getDate()}`

        
        if(date === 'сейчас'){
            setNewDate('сейчас')
            setTime('')
        }else if(date.slice(0, 8) === availDate.slice(0, 8) && Math.abs(Number(date.slice(8, 10)) - Number(availDate.slice(8, 10))) === 1){
            setNewDate('вчера')
            setTime(date.slice(11, 16))
        }else if(date.slice(0, 11) === availDate.slice(0, 11)){
            setNewDate('сегодня')
            setTime(date.slice(11, 16))
        }else{
            setNewDate(date.slice(0, 11).replace('-', '.').replace('-', '.'))
            setTime(date.slice(11, 16))
        }
    }, [date])
    return(
        <div className="comment">
            <div className="personal_info">
                <div className="name_n_date">
                    <div className="icon"></div>
                    <div className="personal_info_text">
                        <div>{name}</div>
                        <div>{`${newDate} ${time}`}</div>
                    </div>
                </div>
                <div className="comm_star_area">
                    <div className={"star" + (score >= 1 ?' active' :'')}></div>
                    <div className={"star" + (score >= 2 ?' active' :'')}></div>
                    <div className={"star" + (score >= 3 ?' active' :'')}></div>
                    <div className={"star" + (score >= 4 ?' active' :'')}></div>
                    <div className={"star" + (score >= 5 ?' active' :'')}></div>
                </div>
            </div>
            <div className="text_part">{children}</div>
            <div></div>
        </div>
    )
}

export default Comment;