import React, { useEffect, useState } from "react";

function ProdSizeItem({setPrice, setSize, index, ...props}){
    const[disabled, setDisabled] = useState(false);
    const[windowWidth, setWindowWidth] = useState(0);
    const[wideSize, setWideSize] = useState(false);

    function set(){
        setPrice(props.price)
        setSize(props.size)
    }

    function handleResize(){
        if(document.getElementsByClassName('size_var')[index].offsetWidth > 280){
            setWideSize(true)
        }
    }
    function handleLeave(){
        setWideSize(false)
    }
    useEffect(() => {
        if(props.price == '-'){
            setDisabled(true)
        }

        document.getElementsByClassName('size_var')[index].addEventListener('mouseover', handleResize)
        document.getElementsByClassName('size_var')[index].addEventListener('mouseleave', handleLeave)
    }, [])
    useEffect(() => {
        if(props.price == '-'){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }, [props.price])
    // useEffect(() => {
    //     if(document.getElementsByClassName('size_var')[0].offsetWidth > 280){
    //         setWideSize(true)
    //     }
    // }, [windowWidth])

    if(disabled){
        return(
            <label className="size_var" onClick={() => {props.price != '-' ?set() :<></>}}>
                <input type="radio" name="size_checker" disabled/>
                <div>
                    <div className='var_size' style={{left:`${wideSize ?-(document.getElementsByClassName('var_size')[index].offsetWidth - document.getElementsByClassName('size_var')[index].offsetWidth) :0}px`}}>{/[0-9]/.test(props.size[1]) ?'EU '+props.size :props.size}</div>
                    <div className="var_price inactive"></div>
                </div>
            </label>
        )
    }else{
        return(
            <label className="size_var" onClick={() => {props.price != '-' ?set() :<></>}}>
                <input type="radio" name="size_checker"/>
                <div>
                    <div className='var_size' style={{left:`${wideSize ?-(document.getElementsByClassName('var_size')[index].offsetWidth - document.getElementsByClassName('size_var')[index].offsetWidth) :0}px`}}>{/[0-9]/.test(props.size[1]) ?'EU '+props.size :props.size}</div>
                    <div className="var_price">{props.price + '₽'}</div>
                </div>
            </label>
        )
    }
}

export default ProdSizeItem;