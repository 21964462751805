import React, { useEffect, useState } from "react";

function SortItem({availSort, setAvailSort, setFilterMode, getList}){
    const[sortText, setSortText] = useState('По популярности');
    const[sortMode, setSortMode] = useState(false);

    useEffect(() => {
        if(availSort != null){
            if(availSort > 0){
                setSortText('По цене')
            }else if(availSort <= 0){
                setSortText('По популярности')
            }
            setSortMode(false)
            getList()
        }
    }, [availSort])
    function CangeSortMode(){
        if(sortMode){
            setSortMode(false)
        }else{
            setSortMode(true)
        }
    }

    return(
        <div className="sort_n_filt">
            <div className="sorter_but">
                <div className="non_hide_part" onClick={CangeSortMode}>
                    <div className={"icon " + (availSort%2 == 0 ?'low_to_high' :'high_to_low')}></div>
                    <div className="text">{sortText}</div>
                </div>
                <div className={"hide_part " + (sortMode ?'' :'unvisible')}>
                    <div className={"sort_item " + (availSort === 2 ?'active' :'')} onClick={() => setAvailSort(2)}>По возрастанию цены</div>
                    <div className={"sort_item " + (availSort === 1 ?'active' :'')} onClick={() => setAvailSort(1)}>По убыванию цены</div>
                    <div className={"sort_item " + (availSort === 0 || availSort === null ?'active' :'')} onClick={() => setAvailSort(0)}>Сначала популярные</div>
                </div>
            </div>
            <div className="filter_but" onClick={() => setFilterMode(true)}>
                <div className="text">Фильтры</div>
                <div className="icon"></div>
            </div>
        </div>
    )
}

export default SortItem;