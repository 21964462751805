import React from "react";
import classes from './HeaderBut.module.css';
import { Link } from "react-router-dom";

const HeaderBut = ({children, to, ...props}) => {
    return(
        <Link to={to}><div {...props} className={classes.header_but}>
            {children}
        </div></Link>
    )
}

export default HeaderBut;