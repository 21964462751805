import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import H1 from "../../UI/H1/H1";
import LikeBut from "../../UI/LikeBut/LikeBut";

function ProdCard({info, setError}){
    const[name, setName] = useState();

    useEffect(() => {
        if(info.name === undefined){
            return
        }

        if(info.name.length > 30){
            let i = 31
            while(info.name[i] != ' '){
                --i
            }
            setName(info.name.slice(0, i).padEnd(i+3, '.'))
        }else{
            setName(info.name)
        }
    }, [])

    return(
        <div className="prod_card">
            <LikeBut dbData={info.is_liked} prodId={info.article[0]} setError={setError}/>
            <Link to={'/product?art=' + encodeURIComponent(info.article)}>
                <div className="gallery">
                    <img src={`data:image/png;base64,${info.photo}`}/>
                </div>
                <div className="info_part">
                    <h1>{info.price + '₽'}</h1>
                    <div className="card_name">{name}</div>
                </div>
            </Link>
            {info.stars !== '--'
                ?<div id="tools_card_part">
                    <div id="card_comm_but"><div>{info.stars}</div></div>
                </div>
                :<></>
            }
        </div>
    )
}

export default ProdCard;