import React, { useEffect, useState } from "react";
import classes from '../../css/Pages/ProductPage.css';
import { axiosAsk, fetchAsk, getCookie } from "../../api/api";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import MainBut from "../UI/MainBut/MainBut";
import ReturnLeft from "../UI/ReturnLeft/ReturnLeft";
import MoreBut from "../UI/MoreBut/MoreBut";
import ShareBut from "../UI/ShareBut/ShareBut";
import LikeBut from "../UI/LikeBut/LikeBut";
import H1 from "../UI/H1/H1";

import ProdSizeItem from "../Parts/StackItems/ProdSizeItem";
import ErrorPage from "./ErrorPage";
import { OkAlert, WarningAlert } from "../UI/Alert/Alert";


function ProductPage({...props}){
    const[OkAlertText, setOkAlertText] = useState('');
    const[WarningAlertText, setWarningAlertText] = useState('');
    const[error, setError] = useState(false);
    const[windowWidth, setWindowWidth] = useState(window.innerWidth);

    const[prod, setProd] = useState({article:1, name:'', brand:'', price:'', description:[], is_liked:false, score:0});
    
    const[availPrice, setAvailPrice] = useState(0);
    const[availSize, setAvailSize] = useState('');

    const[imgs, setImgs] = useState([]);
    const[sizeVars, setSizeVars] = useState([]);
    

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramValue = decodeURIComponent(params.get('art'));




    function requestProd(){
        axiosAsk({oper:'get_item_info_by_id', ask_articles:[paramValue], chat_id:getCookie('user_id'), flag:true})
        .then(response => {
            if(response.data['list'][0]['answer'] === 1){
                setProd({
                    article:paramValue,
                    name:response.data['list'][0]['name'],
                    brand:response.data['list'][0]['brand'],
                    price:response.data['list'][0]['price'],
                    description:[...response.data['list'][0]['description']],
                    score:response.data['list'][0]['stars'],
                    is_liked:response.data['list'][0]['is_liked']
                })
                setAvailPrice(response.data['list'][0]['price'])
            }else{
                navigate('/error_404')
            }
        })
        .catch(response => {setError(true); console.error(response)})

        axiosAsk({oper:'get_cnt_photos_by_tov_id', article:paramValue, flag:true})
        .then(photo_cnt => {
            let count = photo_cnt.data['cnt']
            let imgArr = []
            for(var i = 1; i <= count; ++i){
                axiosAsk({oper: 'get_photo_by_tov_id', article:paramValue, num:i, flag:true})
                .then(response => {
                    imgArr.push(response.data['photo'])
                    setImgs([...imgArr])
                })
                .catch(response => {setError(true); console.error(response)})
            }
            document.getElementById('gallery').parentElement.style.backgroundImage = 'none'
        })
        .catch(response => {setError(true); console.error(response)})
        document.getElementById('gallery').parentElement.style.backgroundImage = 'background-image: url(../img/svg/clock.svg)'

        axiosAsk({oper:'get_sizes_by_id', article:paramValue, flag:true})
        .then(response => {
            let SizeArr = []
            response.data['sizes'].forEach(item => {
                SizeArr.push({size:item[0], price:item[1]}) 
            });
            setSizeVars([...SizeArr])
        })
        .catch(response => {setError(true); console.error(response)})
    }
    useEffect(() => {
        resizeGallery()
    }, [imgs])


    useEffect(() => {
        if(paramValue === undefined || paramValue === ''){
            navigate('/error_404')
        }

        requestProd()
        // axiosAsk({oper:'clicked_tov_with_id', article:paramValue})
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
        window.addEventListener('resize', handleResize);

        function handleResize(){
            setWindowWidth(window.innerWidth);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])



    function HideMenu(target){
        const icon = document.getElementById(target + '_icon')
        const menu = document.getElementById(target + '_show_part')

        if(menu.style.display === 'none' || menu.style.display === ''){
            if(target === 'size_menu'){
                menu.style.display = 'flex'
            }else{
                menu.style.display = 'block'
            }
            icon.style.transform = 'rotate(0deg)'
        }else{
            menu.style.display = 'none'
            icon.style.transform = 'rotate(180deg)'
        }
    }



    useEffect(() => {
        resizeGallery()
        // resizeSizes()
    }, [windowWidth]);

    function resizeGallery(){
        if(document.getElementById('gallery') === undefined || document.getElementById('gallery') === null){
            return
        }

        const gallery = document.getElementById('gallery')

        if(windowWidth < 750){
            gallery.style.width = (window.innerWidth - 40)*imgs.length + 'px'
            gallery.parentElement.style.height = (window.innerWidth - 40)*0.92 + 'px'
        }else if(windowWidth < 1120){
            gallery.style.width = (window.innerWidth - 120)/10*6*imgs.length + 'px'
            gallery.parentElement.style.height = (window.innerWidth - 90)/2*0.92 + 'px'
        }else{
            gallery.style.width = 510*imgs.length + 'px'
            gallery.parentElement.style.height = 510*0.92 + 'px'
        }
        // else if(windowWidth < 1100){
        //     gallery.style.width = (window.innerWidth - 120)/7*4*imgs.length + 'px'
        //     gallery.parentElement.style.height = (window.innerWidth - 120)/7*4*0.92 + 'px'
        // }

        if(document.getElementById('slider_left') === undefined || document.getElementById('slider_left') === null || document.getElementById('slider_right') === undefined || document.getElementById('slider_right') === null){
            return
        }

        if(windowWidth < 750){
            document.getElementById('slider_left').style.height = (window.innerWidth - 40)*0.92 + 'px'
            document.getElementById('slider_left').style.bottom = (window.innerWidth - 40)*0.92 + Number(30) + 'px'
            document.getElementById('slider_right').style.height = (window.innerWidth - 40)*0.92 + 'px'
            document.getElementById('slider_right').style.bottom = (window.innerWidth - 40)*0.92 + Number(30) + 'px'
        }else if(windowWidth < 1000){
            document.getElementById('slider_left').style.height = (window.innerWidth - 90)/2*0.92 + 'px'
            document.getElementById('slider_left').style.bottom = (window.innerWidth - 90)/2*0.92 + Number(30) + 'px'
            document.getElementById('slider_right').style.height = (window.innerWidth - 90)/2*0.92 + 'px'
            document.getElementById('slider_right').style.bottom = (window.innerWidth - 90)/2*0.92 + Number(30) + 'px'
        }else{
            document.getElementById('slider_left').style.height = 400*0.92 + 'px'
            document.getElementById('slider_left').style.bottom = 400*0.92 + Number(30) + 'px'
            document.getElementById('slider_right').style.height = 400*0.92 + 'px'
            document.getElementById('slider_right').style.bottom = 400*0.92 + Number(30) + 'px' 
        }
        // else if(windowWidth < 1100){
            // document.getElementById('slider_left').style.height = (window.innerWidth - 200)/7*4*0.92 + 'px'
            // document.getElementById('slider_left').style.bottom = (window.innerWidth - 200)/7*4*0.92 + Number(30) + 'px'
            // document.getElementById('slider_right').style.height = (window.innerWidth - 200)/7*4*0.92 + 'px'
            // document.getElementById('slider_right').style.bottom = (window.innerWidth - 200)/7*4*0.92 + Number(30) + 'px' 
        // }
    }

    function moveGallery(slider){
        if(document.getElementById('gallery') === null || document.getElementById('gallery') === undefined){
            return
        }

        if(slider === 'slider_left'){
            const element = document.getElementById('gallery')
            const elemPos = Number((element.style.left).replace('px', ''))
            if(0 < Math.abs(elemPos)){
                element.style.left = (elemPos + element.children[0].offsetWidth) + 'px';
            }else{
                element.style.left = (elemPos - element.children[0].offsetWidth)*(element.children.length-1) + 'px'
            }
        }else if(slider === 'slider_right'){
            const element = document.getElementById('gallery')
            const elemPos = Number((element.style.left).replace('px', ''))
            if((element.children.length-1) * element.children[0].offsetWidth > Math.abs(elemPos)){
                element.style.left = (elemPos - element.children[0].offsetWidth) + 'px';
            }else{
                element.style.left = '0px'
            }
        }
        // if(slider === 'slider_left'){
        //     const element = document.getElementById('gallery')
        //     const elemPos = Number((element.style.left).replace('px', ''))


        // }else if(slider === 'slider_right'){
        //     const element = document.getElementById('gallery')
        //     const elemPos = Number((element.style.left).replace('px', ''))

        //     let newArr = []
        //     imgs.forEach((item, index) => {
        //         if(index > 0){
        //             newArr.push(item)
        //         }
        //     })
        //     newArr.push(imgs[0])
        //     setImgs(newArr)
        //     element.style.left = (elemPos - element.children[0].offsetWidth) + 'px';
        // }
    }


    // function resizeSizes(){
    //     if(document.getElementById('size_menu_show_part') === null || document.getElementById('size_menu_show_part') === undefined){
    //         return
    //     }

    //     if(windowWidth < 350){
    //         document.getElementById('size_menu_show_part').style.gridTemplateColumns = 'repeat(3, 33.33%)';
    //     }else if(windowWidth >= 350){
    //         document.getElementById('size_menu_show_part').style.gridTemplateColumns = 'repeat(4, 25%)';
    //     }
    //     // }else if(windowWidth >= 470){ && windowWidth < 470
    //     //     document.getElementById('size_menu_show_part').style.gridTemplateColumns = 'repeat(5, 20%)';
    //     // }
    // }



    function addInCart(){
        if(getCookie('enter') == 'login'){
            if(availSize !== ''){

                axiosAsk({
                    oper:'add_tov_in_busket',
                    chat_id:getCookie('user_id'),
                    article:paramValue,
                    size:availSize,
                    flag:true
                })
                .then(response => {
                    if(response.data['cnt'] != 0 && response.data['cnt'] != ''){
                        setOkAlertText(`Товар успешно добавлен! Товаров в вашей корзине: ${response.data['cnt']}`)
                    }else{
                        setWarningAlertText('Что-то пошло не так! Попробуйте ещё раз')
                    }
                })
                .catch(error => {console.error(error); setError(true)})
            }else{
                setWarningAlertText('Для начала выберите размер')
            }
        }else{
            setWarningAlertText('Войдите в акаунт, чтобы добавить в корзину')
        }
    }

    if(error){
        return(
            <ErrorPage/>
        )
    }

    return(
        <div className='main_prod_part'>
            {WarningAlertText
                ?<WarningAlert setNull={setWarningAlertText}>{WarningAlertText}</WarningAlert>
                :<></>
            }
            {OkAlertText
                ?<OkAlert setNull={setOkAlertText}>{OkAlertText}</OkAlert>
                :<></>
            }
            <div className='navigate_prod_part'>
                <ReturnLeft id={'return_but'}/>
                {windowWidth < 750
                    ?<div className="like_tool">
                        <LikeBut dbData={prod.is_liked} prodId={paramValue} setError={setError}/>
                    </div>
                    :<></>
                }
            </div>
            <div className="all_prod_info">
                <div className="gallery_prod_part">
                    <div className="gallery_move_part" id="gallery">
                        {imgs.map((item, index) =>
                            <img key={index} src={`data:image/png;base64,${item}`}/>
                        )}
                    </div>
                    <div className="gallery_misc_tools">
                        <div>
                            <div className="slider left" id="slider_left" onClick={(e) => moveGallery(e.target.id)}></div>
                            <div className="slider right" id="slider_right" onClick={(e) => moveGallery(e.target.id)}></div>
                        </div>
                    </div>
                </div>
                <div className="usefull_part">
                    <div className="info_prod_part">
                        <div className="prod_name">
                            {prod.name}
                            {windowWidth >= 750
                                ?<LikeBut dbData={prod.is_liked} prodId={paramValue} setError={setError}/>
                                :<></>
                            }
                        </div>
                        <div className="prod_price">
                            <h1>{availPrice + '₽'}</h1>
                        </div>
                        <div className="prod_size hide_menu">
                            <h1>Размеры:</h1>
                            <div id="size_menu_show_part">
                                {sizeVars.map((item, index) => 
                                    <ProdSizeItem key={index} index={index} size={item.size} price={item.price} setPrice={setAvailPrice} setSize={setAvailSize}/>
                                )}
                            </div>
                            {/* <div className="hide_menu_head" id="size_menu" onClick={() => HideMenu('size_menu')}><div id="size_menu_icon"></div>Размеры:</div>
                            <div id="size_menu_show_part">
                                {sizeVars.map((item, index) => 
                                    <ProdSizeItem key={index} index={index} size={item.size} price={item.price} setPrice={setAvailPrice} setSize={setAvailSize}/>
                                )}
                            </div> */}
                        </div>
                        {/* <div className="prod_discription hide_menu">
                            <div className="hide_menu_head" id="disc_menu" onClick={() => HideMenu('disc_menu')}><div id="disc_menu_icon"></div>Описание:</div>
                            <div id="disc_menu_show_part">
                                {prod.description.map((item, index) => 
                                    <p key={index}>{item}</p>
                                )}
                            </div>
                        </div> */}
                    </div>
                    <MainBut id='product_page_buy_but' theme='dark' onClick={() => addInCart()}>ДОБАВИТЬ В КОРЗИНУ</MainBut>
                </div>
            </div>
        </div>
    )
}

export default ProductPage;