import React, { useEffect } from "react";
import '../../css/AccHeader.css';

import ReturnLeft from "../UI/ReturnLeft/ReturnLeft";

function AccHeader({children, backPermit = false, to}){

    if(backPermit){
        return(
            <div className="acc_header_part">
                <ReturnLeft to={to}/>
                <h1>{children}</h1>
                <div className="icon_part"></div>
            </div>
        )
    }else{
        return(
            <div className="acc_header_part">
                <h1>{children}</h1>
                <div className="icon_part"></div>
            </div>
        )
    }
}

export default AccHeader;