import React, { useEffect, useState } from "react";
import classes from './Alert.module.css'

export const WarningAlert = ({children, setNull}) => {
    const[topPosition, setTopPosition] = useState(-60);

    useEffect(() => {
        startAnimation()
    }, [children])

    function startAnimation(){
        if(children !== '' && children != undefined){            
            setTimeout(() => {
                if(window.innerWidth < 700){
                    setTopPosition(120)
                }else{
                    setTopPosition(100)
                }
            }, 100)
            setTimeout(() => {
                setTopPosition(-60)
                setTimeout(() => {
                    setNull('')
                }, 300)
            }, 4000)
        }
    }


    return(
        <div className={classes.window_place} style={{top:`${topPosition}px`}}>
            <div className={classes.alert_window}>
                <div className={classes.warning_icon}></div>
                <div className={classes.text_part}>{children}</div>
            </div>
        </div>
    )
}

export const OkAlert = ({children, setNull}) => {
    const[topPosition, setTopPosition] = useState(-60);

    useEffect(() => {
        startAnimation()
    }, [children])

    function startAnimation(){
        if(children !== '' && children != undefined){            
            setTimeout(() => {
                if(window.innerWidth < 700){
                    setTopPosition(120)
                }else{
                    setTopPosition(100)
                }
            }, 100)
            setTimeout(() => {
                setTopPosition(-60)
                setTimeout(() => {
                    setNull('')
                }, 300)
            }, 4000)
        }
    }

    return(
        <div className={classes.window_place} style={{top:`${topPosition}px`}}>
            <div className={classes.alert_window}>
                <div className={classes.ok_icon}></div>
                <div className={classes.text_part}>{children}</div>
            </div>
        </div>
    )
}