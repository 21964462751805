import React, { useEffect, useState } from "react";

function AddressChecker({type, setMode, mode, data, index, availAddress, setAvailAddress, setUpdateDate}){
    const[checked, setChecked] = useState(false);
    const[shippingCo, setShippingCo] = useState('');

    useEffect(() => {
        if(checked && availAddress !== data.name){
            setAvailAddress(data?.name)
        }
    }, [checked])
    useEffect(() => {
        if(availAddress !== data?.name){
            setChecked(false)
        }
    }, [availAddress])
    useEffect(() => {
        if(document.getElementsByClassName('address_checker')[index] == null || document.getElementsByClassName('address_checker')[index] === undefined){
            return
        }

        if(mode === 'change'){
            document.getElementsByClassName('address_checker')[index].style.width = '0'
            document.getElementsByClassName('icon')[index].style.width = '30px'
        }else{
            document.getElementsByClassName('address_checker')[index].style.width = '30px'
            document.getElementsByClassName('icon')[index].style.width = '0px'
        }
    }, [mode])
    useEffect(() => {
        if(data != undefined){
            if(data.sc_name == 'yandex'){
                setShippingCo('Яндекс')
            }else if(data.sc_name == 'russia'){
                setShippingCo('Почта России')
            }else if(data.sc_name == 'cdek'){
                setShippingCo('СДЕК')
            }
        }
    }, [data])

    function addressClick(){
        if(mode === 'shipping'){
            if(!checked){
                setChecked(true)
            }
        }else if(mode === 'change'){
            setMode('update')
            setUpdateDate(data)
        }
    }



    if(type === 'add'){
        return(
            <div className="prog_address" onClick={() => setMode('add')}>
                <div className="address_text_part">Добавить новый адрес</div>
                <div className="icon" id="address_add"></div>
            </div>
        )
    }else if(type === 'change'){
        return(
            <div className="prog_address" onClick={() => {mode === 'change' ?setMode('shipping') :setMode('change')}}>
                <div className="address_text_part">{mode === 'change' ?'Отменить редактирование' :'Редактировать адреса'}</div>
                <div className="icon" id="address_update"></div>
            </div>
        )
    }else if(type === 'read'){
        return(
            <div className={`address ${checked ?'checked' :''}`} onClick={() => addressClick()}>
                <div className="address_checker"></div>
                <div className="address_text_part">
                    <div className="address_name_part">{data?.name}</div>
                    <div className="address_part">{`${shippingCo}, ${data?.city}, ${data?.address}`}</div>
                </div>
                <div className="icon"></div>
            </div>
        )
    }
}

export default AddressChecker;