import React, { useEffect, useState } from "react";
import AccHeader from "./AccHeader";
import '../../css/Filters.css'
import MainBut from "../UI/MainBut/MainBut";
import OutsideClickHandler from "react-outside-click-handler";
import { axiosAsk } from "../../api/api";

function FilterPart({costMin, costMax, isMan, checkedTypes, checkedBrands, Apply, setFilterMode, setError}){
    const[availCostMin, setAvailCostMin] = useState(costMin == undefined ?'' :costMin);
    const[availCostMax, setAvailCostMax] = useState(costMax == undefined ?'' :costMax);
    const[isManGender, setIsManGender] = useState(isMan);
    const[brandList, setBrandList] = useState([]);
    const[smallBrandList, setSmallBrandList] = useState([]);
    const[allBrandsMode, setAllBrandsMode] = useState(false);
    const[availBrands, setAvailBrands] = useState([]);
    const[availTypes, setAvailTypes] = useState([]);

    const type_names = [
        {id:'underpants', name:'Нижнее белье'}, {id:'shoes', name:'Обувь'}, {id:'tshorts', name:'Футболки'}, {id:'pants', name:'Штаны'}, {id:'accessories', name:'Аксессуары'}, {id:'shorts', name:'Шорты'},
        {id:'hoodies', name:'Кофты'}, {id:'jackets', name:'Верхняя одежда'}, {id:'bags', name:'Сумки'}, {id:'skirts', name:'Юбки'},
    ]

    // function checkInput(func, value){
    //     if(/^\d+$/.test(value)){
    //         if(func == 'costMax'){
    //             setAvailCostMax(value)
    //         }else if(func == 'costMin'){
    //             setAvailCostMin(value)
    //         }   
    //     }
    // }


    useEffect(() => {
        getBrandNames()
        setChecked('types', checkedTypes)

        window.addEventListener('keypress', KeyPress);
        return() => {
            window.removeEventListener('keypress', KeyPress);
        }
    }, [])

    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            const inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === document.activeElement) {
                if (i < inputs.length - 1) {
                    inputs[i + 1].focus();
                    break;
                }
                }
            }
        }
    }
    function getBrandNames(){
        axiosAsk({oper:'get_brands_list', flag:true})
        .then(response => {
            setBrandList(response.data['brands'])
            setSmallBrandList(response.data['brands'].slice(0, 5))
        })
        .catch(error => {console.error(error); setError(true)})
    }
    function setChecked(oper, list){
        if(list == undefined){
            return
        }

        let i = 2
        while(i < 12){
            console.log()
            if(list.findIndex(item => document.getElementsByTagName('input')[i].value == item) != -1){
                document.getElementsByTagName('input')[i].checked = true
                addCheckerToList('type', document.getElementsByTagName('input')[i].value)
            }

            ++i
        }
    }
    function addCheckerToList(type, item){
        if(type == 'type'){
            console.log(availTypes, item)
            if(availTypes.includes(item)){
                let newArr = []
                availTypes.forEach(type_name => {
                    if(type_name != item){
                        newArr.push(type_name)
                    }
                })
                setAvailTypes(newArr)
            }else{
                setAvailTypes([...availTypes, item])
            }
        }else if(type == 'brand'){
            if(availBrands.includes(item)){
                let newArr = []
                availBrands.forEach(brand_name => {
                    if(brand_name != item){
                        newArr.push(brand_name)
                    }
                })
                setAvailBrands(newArr)
            }else{
                setAvailBrands([...availBrands, item])
            }
        }
    }
    useEffect(() => {
        console.log(availTypes)
    }, [availTypes])
    useEffect(() => {
        console.log(availBrands)
    }, [availBrands])

    return(
        <div className="filter_place">
            <OutsideClickHandler onOutsideClick={() =>  setFilterMode(false)}>
                <div className="filter">
                    <AccHeader>
                        <div className="order_return_but" onClick={() => setFilterMode(false)}></div>
                        <h1 className="order_header_h1">ФИЛЬТРЫ</h1>
                    </AccHeader>
                    <div className="gender_filter">
                        <div className="gender_filter_place">
                            <div className="text_place" onClick={() => setIsManGender(true)}>Мужское</div>
                            <div className="helper_div"></div>
                            <div className="text_place" onClick={() => setIsManGender(false)}>Женское</div>
                            <div className="draft_avail_border" style={{left:`${isManGender ?3 :130}px`}}></div>
                        </div>
                    </div>
                    <div className="cost_filter">
                        <h1 className="text">ЦЕНА</h1>
                        <div>
                            <div className="input">
                                <div>от</div>
                                <input
                                    type="text"
                                    value={availCostMin}
                                    onChange={(e) => setAvailCostMin(!isNaN(e.target.value) ?e.target.value :availCostMin)}
                                />
                            </div>
                            <div className="input">
                                <div>до</div>
                                <input
                                    type="text"
                                    value={availCostMax}
                                    onChange={(e) => setAvailCostMax(!isNaN(e.target.value) ?e.target.value :availCostMax)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="type_filter">
                        <h1>ТИП ТОВАРА</h1>
                        <div className="type_list">
                            {type_names.map((item, index) =>
                                <label key={index}><input type="checkbox" name="type_check" value={item.id} onClick={() => addCheckerToList('type', item.id)}/><div>{item.name}</div></label>
                            )}
                        </div>
                    </div>
                    <div className="size_filter"></div>
                    <div className="brand_filter">
                        <div className="brand_header">
                            <h1>БРЕНДЫ</h1>
                            {allBrandsMode
                                ?<div onClick={() => setAllBrandsMode(false)}>СКРЫТЬ</div>
                                :<></>
                            }
                        </div>
                        {allBrandsMode
                            ?<div className="full_list_filter">
                                {brandList.map((item, index) =>
                                    <label key={index} className="brand_checkbox"><input type="checkbox" name="brand_check" onClick={() => addCheckerToList('brand', item[0])}/><div>{item}</div></label>
                                )}
                            </div>
                            :<div className="small_list_filter">
                                {smallBrandList.map((item, index) =>
                                    <label key={index} className="brand_checkbox"><input type="checkbox" name="brand_check" onClick={() => addCheckerToList('brand', item[0])}/><div>{item}</div></label>
                                )}
                                <div className="more_brand_but" onClick={() => setAllBrandsMode(true)}>Все бренды...</div>
                            </div>
                        }
                    </div>
                    <MainBut theme={'dark'} id='apply_but' onClick={() => Apply(availCostMin, availCostMax, isManGender, availTypes, availBrands)}>Применить</MainBut>
                </div>
            </OutsideClickHandler>
        </div>
    )
}
 
export default FilterPart;