import React, { useEffect, useState } from "react";
import '../../css/Pages/CartPage.css';
import { axiosAsk, getCookie } from "../../api/api";
import { useNavigate } from "react-router-dom";

import InCartProd from "../Parts/StackItems/InCartProd";
import ReturnLeft from "../UI/ReturnLeft/ReturnLeft";
import H1 from "../UI/H1/H1";
import ErrorPage from "./ErrorPage";
import MainBut from "../UI/MainBut/MainBut";
import { WarningAlert } from "../UI/Alert/Alert";

function CartPage(){
    const[alertText, setAlertText] = useState('');
    const[IsIn, setIn] = useState(false);
    const[error, setError] = useState(false);
    const[moreMode, setMoreMode] = useState(false);
    const[wait, setWait] = useState(false);

    const[prods, setProds] = useState([]);
    const[cartProds, setCartProds] = useState([]);

    const[totalPrice, setTotalPrice] = useState(0);
    const[checkedProds, setCheckedProds] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if(getCookie('enter') != 'login'){
            navigate('/login')
        }
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});

        setWait(true);
        axiosAsk({
            oper:'get_busket',
            chat_id:getCookie('user_id'),
            flag:true
        })
        .then(response => {
            let all_product_price = 0
            response.data['items'].forEach(item => {
                all_product_price += Number(item.cost.replace(' ', '').replace(' ', '')) * Number(item.cnt)
            })

            setCartProds(response.data['items']);
            setTotalPrice(all_product_price);
            setWait(false);
        })
        .catch(error => {console.error(error); setError(true)})
    }, [])
    useEffect(() => {
        if(cartProds.length == 0){
            return
        }

        let Ids = []
        let Sizes = []
        cartProds.forEach((prod) => {
            Ids.push(prod.article)
            Sizes.push(prod.size)
        })

        setWait(true);
        axiosAsk({
            oper:'get_item_info_by_id',
            ask_articles:Ids,
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            setProds(response.data['list']);
            setWait(false);
        })
        .catch(error => {console.error(error); setError(true)})
    }, [cartProds])

    
    useEffect(() => {
        if(cartProds !== undefined && cartProds.length !== 0){
            setIn(true)
        }
    }, [prods])

    useEffect(() => {
        if(!moreMode && cartProds.length){
            let all_product_price = 0
            cartProds.forEach(item => {
                all_product_price += Number(item.cost.replace(' ', ''))
            })

            setTotalPrice(all_product_price);
        }else{
            setTotalPrice(0)
        }
    }, [moreMode])



    function removeProd(array_for_del){
        let checkedProds = []
        let newProds = []
        let newCart = []
        prods.forEach((item) => {
            let flag = true
            for(let i = 0; i < array_for_del.length; ++i){
                if(array_for_del[i].article == item.article[0] && array_for_del[i].size == item.size){
                    flag = false
                }
            }
            if(flag){
                newProds.push(item)
            }
        })
        cartProds.forEach((item) => {
            let flag = true
            for(let i = 0; i < array_for_del.length; ++i){
                if(array_for_del[i].article == item.article && array_for_del[i].size == item.size){
                    checkedProds.push({size:item.size, article:item.article})
                    flag = false
                }
            }
            if(flag){
                newCart.push(item)
            }
        })

        axiosAsk({
            oper:'delete_tov_from_busket',
            chat_id:getCookie('user_id'),
            list:checkedProds,
            flag:true
        })
        .then(response => {
            if(response.data['status'] === 'ok'){
                setProds(newProds)
                setCartProds(newCart)
            }else{
                setAlertText('Что-то пошло не так! Попробуйте ещё раз')
            }
        })
        .catch(error => {console.error(error); setError(true)})

        if(newProds.length == 0){
            setIn(false)
        }

        console.log(newProds, newCart)
    }
    function removeSome(){
        if(checkedProds.length === 0){
            setAlertText('Нет выбранных товаров')
            return
        }

        removeProd(checkedProds)
        changeMode()
    }

    function changeMode(){
        if(moreMode){
            setMoreMode(false)
            setCheckedProds([])
            setTotalPrice(0)
        }else{
            setMoreMode(true)
        }
    }
    function addChecked(product_article, product_size, remove = false){
        if(remove){
            let newProds = []
            let price = 0
            checkedProds.forEach(item => {
                if(item.article != product_article || item.size != product_size){
                    newProds.push(item)
                    price += Number(cartProds[cartProds.findIndex(prod => prod.article == item.article && prod.size == item.size)].cost.replace(' ', ''))
                }
            })
            setCheckedProds(newProds)

            setTotalPrice(price)
        }else{
            setCheckedProds([...checkedProds, {article:product_article, size:product_size}])
            const avail_prod = cartProds[cartProds.findIndex(prod => prod.article == product_article && prod.size == product_size)]
            setTotalPrice(totalPrice + Number(avail_prod.cost.replace(' ', '')) * Number(avail_prod.cnt))
        }
    }

    function changeCount(article, size, count){
        let newArr = []
        let total_price = 0
        cartProds.forEach(prod => {
            if(prod.article == article && prod.size == size){
                let newProd = {article:article, size:size, cnt:count, cost:prod.cost}
                total_price += Number(newProd.cost.replace(' ', '')) * newProd.cnt
                newArr.push(newProd)
            }else{
                total_price += Number(prod.cost.replace(' ', '')) * prod.cnt
                newArr.push(prod)
            }
        })
        setCartProds(newArr)
        setTotalPrice(total_price)

    }

    
    if(error){
        return(
            <ErrorPage/>
        )
    }

    return(
        <div className="cart_page">
            {alertText
                ?<WarningAlert setNull={setAlertText}>{alertText}</WarningAlert>
                :<></>
            }
            <div className="cart_header_part">
                <ReturnLeft/>
                <h1>МОЯ КОРЗИНА</h1>
                <div className="icon_part"></div>
                {IsIn
                    ?(!moreMode 
                        ?<MainBut id='but' theme={'dark'} onClick={changeMode}>ВЫБРАТЬ</MainBut>
                        :<div className="but_part">
                            <MainBut id='but_cancel' theme={'dark'} onClick={changeMode}></MainBut>
                            <MainBut id='but_del' theme={'dark'} onClick={removeSome}></MainBut>
                        </div>
                    )
                    :<></>
                }
            </div>
            <div className="cart_prods_part">
                {cartProds?.map((prod, index) => 
                    <InCartProd
                        key={index}
                        index={index}
                        info={prods[index]}
                        moreMode={moreMode}
                        size={prod?.size}
                        price={prod?.cost}
                        count={prod?.cnt}
                        setCount={changeCount}
                        addChecked={addChecked}
                        Delete={removeProd}
                        setError={setError}
                    />
                )}
            </div>
            {wait
                ?<div className="wait_icon"></div>
                :<></>
            }
            {!cartProds.length && !wait
                ?<div className="empty_cart">Пока ваша корзина пуста!</div>
                :<></>
            }
            {checkedProds.length || (!moreMode && cartProds.length)
                ?<div className="more_mode_info">
                    <div className="total_price">{'Итого: ' + totalPrice + '₽'}</div>
                    <MainBut id='total_buy' theme={'dark'} onClick={() => navigate('/checkout?avail=' + (checkedProds.length ?encodeURI(`${checkedProds.map(item => item.article)};${checkedProds.map(item => item.size)}`) :encodeURI(`${cartProds.map(item => item.article)};${cartProds.map(item => item.size)}`)))}>{!moreMode ?'КУПИТЬ ВСЁ' :'К ОФОРМЛЕНИЮ'}</MainBut>
                </div>
                :<></>
            }
        </div>
    )
}

export default CartPage;