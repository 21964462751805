import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import '../../css/Pages/OrderPage.css';
import ErrorPage from "./ErrorPage";

import AccHeader from "../Parts/AccHeader";
import MainBut from "../UI/MainBut/MainBut";
import { axiosAsk, getCookie } from "../../api/api";
import AddressChecker from "../Parts/StackItems/AddressChecker";
import InCartProd from "../Parts/StackItems/InCartProd";
import { OkAlert, WarningAlert } from "../UI/Alert/Alert";

function OrderPage(){
    const[error, setError] = useState(false);
    const[wait, setWait] = useState(0);
    const[warningAlert, setWarningAlert] = useState('');
    const[okAlert, setOkAlert] = useState('');
    
    const[cartProds, setCartProds] = useState([]);
    const[prods, setProds] = useState([]);
    
    const[mode, setMode] = useState('check');
    const[shippingList, setShippingList] = useState([]);
    const[savedShipping, setSavedShippng] = useState(null);
    const[availShipping, setAvailShipping] = useState(null);
    const[newShipping, setNewShipping] = useState({sc_name:'', city:'', address:'', massage:''});
    const[availPayment, setAvailPayment] = useState(null);
    const[deliveryPrice, setDeliveryPrice] = useState(0);
    const[totalPrice, setTotalPrice] = useState(0);
    const[finalBill, setFinalBill] = useState(0);
    
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const availCart = params.get('avail');




    useEffect(() => {
        getAddressList()
        getCart()
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
        window.addEventListener('keypress', KeyPress);
        return() => {
            window.removeEventListener('keypress', KeyPress);
        }
    }, [])
    useEffect(() => {
        setDeliveryPrice(300)
    }, [availShipping])
    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
        if(mode == 'update'){
            setSavedShippng(newShipping)
        }

        if(newShipping.sc_name != '' && (mode == 'update' || mode == 'add')){
            let i = 1
            while(i < 4){
                if(document.getElementsByTagName('input')[i].value == newShipping.sc_name){
                    document.getElementsByTagName('input')[i].checked = true
                    return
                }
                ++i
            }
        }
    }, [mode])


    function getAddressList(){
        setWait(1)
        axiosAsk({
            oper:'get_delivery_info',
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            let list = []
            response.data.forEach(item => {
                let last_index = 0
                let now_address= []
                for(let i = 0; i<5; ++i){
                    if(item.indexOf('?', last_index) !== -1){
                        now_address[i] = item.slice(last_index, item.indexOf('?', last_index))
                        last_index = Number(item.indexOf('?', last_index))+1
                    }else{
                        now_address[i] = item.slice(last_index)
                    }
                }
                list.push({sc_name:now_address[0], name:now_address[1], city:now_address[2], address:now_address[3], massage:now_address[4]})
            });
            setShippingList(list)
            setWait(0)
        })
        .catch(response => {setError(true); console.error(response)})
    }
    function addNewAddress(){
        if(
            newShipping.sc_name.indexOf('?') !== -1 || 
            newShipping.name.indexOf('?') !== -1 || 
            newShipping.city.indexOf('?') !== -1 || 
            newShipping.address.indexOf('?') !== -1 || 
            newShipping.massage.indexOf('?') !== -1
        ){
            setWarningAlert('Данные не должны содержать символ "?"')
            return
        }
        if(
            newShipping.sc_name.length === 0 || 
            newShipping.name.length === 0 || 
            newShipping.city.length === 0 || 
            newShipping.address.length === 0
        ){
            console.log(newShipping.sc_name)
            setWarningAlert('Заполните все обязательные поля')
            return
        }

        setWait(3)
        axiosAsk({
            oper:'add_delivery_info',
            flag:true,
            chat_id:getCookie('user_id'),
            info:`${newShipping.sc_name}?${newShipping.name}?${newShipping.city}?${newShipping.address}?${newShipping.massage}`
        })
        .then(response => {
            if(response.data['status'] === 'ok'){
                setMode('shipping')
                setShippingList([newShipping, ...shippingList])
                setNewShipping({sc_name:'', name:'', city:'', address:'', massage:''})
            }
            setWait(0)
        })
        .catch(response => {setError(true); console.error(response)})
    }

    function updateAddress(){
        deleteAddress(savedShipping, 'add')
    }

    function deleteAddress(shipping, oper){
        if(oper === 'return'){
            setWait(2)
        }else{
            setWait(3)
        }
        axiosAsk({
            oper:'delete_delivery_info',
            flag:true,
            chat_id:getCookie('user_id'),
            info:`${shipping.sc_name}?${shipping.name}?${shipping.city}?${shipping.address}?${shipping.massage != undefined ?shipping.massage :''}`
        })
        .then(() => {
            setSavedShippng(null)

            if(oper === 'return'){
                getAddressList()
                setNewShipping({name:'', city:'', address:'', massage:''})
                setMode('shipping')
                setOkAlert('Успешно удалено!')
            }else if(oper === 'add'){
                addNewAddress()
                getAddressList()
            }
            setWait(0)
        }
        )
        .catch(response => {setError(true); console.error(response)})
    }


    async function getCart(){
        if(availCart == undefined){
            navigate('/cart')
            return
        }

        let checkProds = []
        let checkIds = availCart.split(';')[0].split(',')
        let checkSizes = availCart.split(';')[1].split(',')
        for(let i = 0; i < checkIds.length; ++i){
            checkProds.push({article:checkIds[i], size:checkSizes[i]})
        }

        setWait(true)
        const cart = await axiosAsk({
            oper:'get_busket',
            chat_id:getCookie('user_id'),
            flag:true
        })
        .then(setWait(false))
        .catch(error => {console.error(error); setError(true)})

        if(cart.data['items'].length == 0){
            return
        }

        let Ids = []
        let Sizes = []
        let cart_for_last_bill = []
        let cart_for_offer = []
        let Total = 0
        cart.data['items'].forEach(cart_prod => {
            checkProds.forEach(check_prod => {
                if(cart_prod.article == check_prod.article && cart_prod.size == check_prod.size){
                    Ids.push(cart_prod.article)
                    Sizes.push(cart_prod.size)
                    Total += Number(cart_prod.cost.replace(' ', ''))
                    cart_for_last_bill.push(cart_prod)
                    cart_for_offer.push([cart_prod.article, cart_prod.size])
                }
            })
        })
        setTotalPrice(Total)
        setCartProds(cart_for_last_bill)

        setWait(true)
        axiosAsk({
            oper:'get_item_info_by_id',
            ask_articles:Ids,
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            setProds(response.data['list'])
            setWait(false)
        })
        .catch(error => {console.log(error); setError(true)})

        setWait(true)
        axiosAsk({
            oper:'get_last_bill',
            chat_id:getCookie('user_id'),
            busket:cart_for_last_bill,
            flag:true,
        })
        .then(response => {
            setWait(false)
            setFinalBill(response.data['cost'])
        })
        .catch(error => {console.error(error); setError(true)})
    }




    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            const inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === document.activeElement) {
                if (i < inputs.length - 1) {
                    inputs[i + 1].focus();
                    break;
                }
                }
            }
        }
    }
    function Payment(){
        if(availShipping == null){
            setWarningAlert('Укажите адрес доставки')
            return
        }

        setWait(1)
        axiosAsk({
            oper:'make_last_offer',
            chat_id:getCookie('user_id'),
            busket:cartProds,
            flag:true,
        })
        .then(() => {
            setWait(0)
            setMode('success')

        })
        .catch(error => {console.error(error); setError(true)})
    }




    if(error){
        return(
            <ErrorPage/>
        )
    }

    if(mode === 'check'){
        return(
            <div className="order_page">
                <WarningAlert setNull={setWarningAlert}>{warningAlert}</WarningAlert>
                <AccHeader backPermit={true}>ОФОРМЛЕНИЕ ЗАКАЗА</AccHeader>
                <div className="order_section">
                    <div className="part_header">Адрес доставки:</div>
                    <div className="order_link_but" onClick={() => setMode('shipping')}>
                        <div className="icon" id="shipping_icon"></div>
                        <div className="text_part">{availShipping === null ?'Выберите адрес' :availShipping}</div>
                        <div className="pointer"></div>
                    </div>
                </div>
                <div className="order_section">
                    <div className="part_header">Ваша корзина:</div>
                    <div className="order_cart_part">
                        {prods.map((item, index) => 
                            <InCartProd
                                key={index}
                                setError={setError}
                                index={index}
                                info={item}
                                size={cartProds[index].size}
                                price={cartProds[index].cost}
                                count={cartProds[index].cnt}
                                position={'order'}
                            />
                        )}
                    </div>
                    {prods.length
                        ?<div className="part_header order_total">{`Итого: ${finalBill}₽`}</div>
                        :<div className="wait_icon"></div>
                    }
                    {/* {finalBill != totalPrice
                        ?<div className="nonequal_price">Цена может отличаться, уточните, почему она изменилась у администратора</div>
                        :<></>
                    } */}
                </div>
                <div className="order_section">
                    <div className="part_header">Способ оплаты:</div>
                    <div className="warning_massage">У нас пока доступен только один способ оплаты - это перевод на банковскую карту</div>
                    {/* <div className="order_link_but" onClick={() => setMode('payment')}>
                        <div className="icon" id="payment_icon"></div>
                        <div className="text_part">{availPayment === null ?'Выберите оплату' :availPayment}</div>
                        <div className="pointer"></div>
                    </div> */}
                </div>
                {cartProds.length
                    ?<MainBut id='onpay_order_but' theme={'dark'} wait={wait} right_num={1} onClick={Payment}>ПОДТВЕРДИТЬ</MainBut>
                    :<></>
                }
            </div>
        )
    }else if(mode === 'shipping' || mode === 'change'){
        return(
            <div className="order_shipping_page">
                <OkAlert setNull={setOkAlert}>{okAlert}</OkAlert>
                <WarningAlert setNull={setWarningAlert}>{warningAlert}</WarningAlert>
                <AccHeader>
                    <div className="order_return_but" onClick={() => setMode('check')}></div>
                    <h1 className="order_header_h1">АДРЕС ДОСТАВКИ</h1>
                </AccHeader>
                <div className="addresses">
                    {shippingList.map((item, index) => 
                        <AddressChecker key={index} type={'read'} setMode={setMode} mode={mode} data={item} index={index} availAddress={availShipping} setAvailAddress={setAvailShipping} setUpdateDate={setNewShipping}/>
                    )}
                    {mode === 'shipping'
                        ?<AddressChecker type={'add'} setMode={setMode} mode={mode}/>
                        :<></>
                    }
                    {shippingList.length
                        ?<AddressChecker type={'change'} setMode={setMode} mode={mode}/>
                        :<></>
                    }
                </div>
                {mode === 'shipping'
                    ?<MainBut id='shipping_but' theme={'dark'} wait={wait} right_num={1} onClick={() => setMode('check')}>Применить</MainBut>
                    :<></>
                }
            </div>
        )
    }else if(mode === 'add' || mode === 'update'){
        return(
            <div className="add_shipping">
                <WarningAlert setNull={setWarningAlert}>{warningAlert}</WarningAlert>
                <AccHeader>
                    <div className="order_return_but" onClick={() => setMode('shipping')}></div>
                    <h1 className="order_header_h1">{mode === 'add' ?'НОВЫЙ АДРЕС' :'РЕДАКТИРОВАТЬ АДРЕС'}</h1>
                </AccHeader>
                <div className="add_shipping_inputs">
                    <div className="add_ship_text">Название (видно только вам)*:</div>
                    <input
                        type="text"
                        id="name"
                        placeholder="У дома/работа"
                        value={newShipping.name}
                        onChange={(e) => setNewShipping({...newShipping, name:e.target.value})}
                    />
                    <div className="add_ship_text">Транспортная компания*:</div>
                    <div className="sc_part">
                        <label onClick={() => setNewShipping({...newShipping, sc_name:'yandex'})}>
                            <input type="radio" name="sc_name" value={'yandex'}/>
                            <div className="sc_item">Яндекс Доставка</div>
                        </label>
                        <label onClick={() => setNewShipping({...newShipping, sc_name:'russia'})}>
                            <input type="radio" name="sc_name" value={'russia'}/>
                            <div className="sc_item">Почта России</div>
                        </label>
                        <label onClick={() => setNewShipping({...newShipping, sc_name:'cdek'})}>
                            <input type="radio" name="sc_name" value={'cdek'}/>
                            <div className="sc_item">CDEK</div>
                        </label>
                    </div>
                    <div className="add_ship_text">Ваш город*:</div>
                    <input
                        type="text"
                        id="city"
                        placeholder="Выберите город"
                        value={newShipping.city}
                        onChange={(e) => setNewShipping({...newShipping, city:e.target.value})}
                    />
                    <div className="add_ship_text">Ваш адрес*:</div>
                    <input
                        type="text"
                        id="address"
                        placeholder="Ваш адрес"
                        value={newShipping.address}
                        onChange={(e) => setNewShipping({...newShipping, address:e.target.value})}
                    />
                    <div className="add_ship_text">Ваш комментарий:</div>
                    <input
                        type="text"
                        id="massage"
                        placeholder="Ваш комментарий"
                        value={newShipping.massage}
                        onChange={(e) => setNewShipping({...newShipping, massage:e.target.value})}
                    />
                </div>
                {mode === 'update'
                    ?<div className="update_buttons_part">
                        <MainBut theme={'dark'} id='add_shipping_button' wait={wait} right_num={2} onClick={() => deleteAddress(savedShipping, 'return')}>Удалить</MainBut>
                        <div className="empty"></div>
                        <MainBut theme={'dark'} id='add_shipping_button' wait={wait} right_num={3} onClick={() => updateAddress()}>Сохранить</MainBut>
                    </div>
                    :<MainBut theme={'dark'} id='add_shipping_button' wait={wait} right_num={3} onClick={() => addNewAddress()}>Сохранить</MainBut>
                }
            </div>
        )
    }else if(mode == 'success'){
        return(
            <div className="success_page">
                <h1>ЗАКАЗ УСПЕШНО СОЗДАН!</h1>
                <div className="success_info">В ближайшее время с вами свяжется менеджер через Телеграмм для подтверждения и оплаты заказа</div>
                <div className="success_info">Спасибо за ваш заказ, ждём вас снова!</div>
                <div className="success_logo">KODR</div>
                <MainBut id='success_butt' theme={'dark'} onClick={() => navigate('/')}>НА ГЛАВНУЮ</MainBut>
            </div>
        )
    }
}

export default OrderPage;