import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import HeaderBut from "../../UI/HeaderBut/HeaderBut";
import FilterPart from "../FilterPart";
import OutsideClickHandler from "react-outside-click-handler";

function Header({Apply}){
    const[searchMode, setSearchMode] = useState(false);
    const[search, setSearch] = useState('');
    const[filterMode, setFilterMode] = useState(false);

    const[buttonMargin, setButtonMargin] = useState(13);
    const[windowWidth, setWindowWidth] = useState(window.innerWidth);
    const[permit, setPermit] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        if(windowWidth > 500){
            setPermit(true)
        }else{
            setPermit(false)
        }

        if(windowWidth < 750){
            setButtonMargin(0)
        }else if(windowWidth <= 980){
            setButtonMargin(13)
        }else{
            setButtonMargin(50)
        }
    }, [windowWidth])
    useEffect(() => {
        if(searchMode){
            document.getElementsByTagName('input')[0].focus()
        }else{
            // document.getElementsByTagName('input')[0].blur()
            if(windowWidth < 750){
                setButtonMargin(0)
            }else if(windowWidth < 980){
                setButtonMargin(13)
            }else{
                setButtonMargin(50)
            }
        }
    }, [searchMode])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('keypress', KeyPress);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('keypress', KeyPress);
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    function chengeFilterMode(){
        if(filterMode){
            setFilterMode(false)
        }else{
            setFilterMode(true)
        }
    }

    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            goSearch()
        }
    }
    function goSearch(){
        document.getElementsByTagName('input')[0].blur()
        navigate('/')
        Apply(document.getElementsByTagName('input')[0].value)
        setTimeout(() => {
            document.getElementById('scroll_wrapper').scrollTo({top: 700, behavior : "smooth"});
        }, 200)
        setSearchMode(false);
    }

    if(searchMode && windowWidth < 750){
        return(
            <OutsideClickHandler onOutsideClick={() => setSearchMode(false)}>
                <header className="mobile_search_header">
                    <div className="return" onClick={() => setSearchMode(false)}></div>
                    <div className="search_bar_place">
                        <div className="search_bar">
                            <div className="search_icon" onClick={goSearch}></div>
                            <input
                                type="text"
                                placeholder="поиск"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className='close_icon' onClick={() => setSearch('')}></div>
                            {/* <div className="filter_icon" onClick={chengeFilterMode}></div> */}
                        </div>
                    </div>
                    {/* <div className="search_history">
                        <div className="history_part">
                            <div className="header">История поиска</div>
                            <div className="history_items">

                            </div>
                        </div>
                    </div> */}
                    {filterMode
                        ?<FilterPart setFilterMode={setFilterMode}/>
                        :<></>
                    }
                </header>
            </OutsideClickHandler>
        )
    }else{
        return(
            <header>
                <div className="top_line">
                    {windowWidth < 750
                        ?<div className="logo_part" onClick={() => navigate('/')}>KODR</div>
                        :<></>
                    }
                    <div className="side_help_butts">
                        {searchMode
                            ?<OutsideClickHandler onOutsideClick={() => setSearchMode(false)}><div>
                                <div className="search_bar">
                                    <div className="search_icon" onClick={goSearch}></div>
                                    <input
                                        type="text"
                                        placeholder="поиск"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <div className='close_icon' onClick={() => setSearch('')}></div>
                                    {/* <div className="filter_icon" onClick={chengeFilterMode}></div> */}
                                </div>
                            </div></OutsideClickHandler>
                            :<div className="icon_div"><div className='search_icon' onClick={() => setSearchMode(true)}>Поиск</div></div>
                        }
                        <div className="icon_div"><div className='favor_icon' onClick={() => navigate('/favorites')}>Избранное</div></div>
                        <div className="icon_div"><div className='cart_icon' onClick={() => navigate('/cart')}>Корзина</div></div>
                        <div className="icon_div"><div className='acc_icon' onClick={() => navigate('/profile')}>Профиль</div></div>
                    </div>
                </div>
                {windowWidth < 850 && windowWidth > 749 && searchMode
                    ?<></>
                    :<div className="bot_line">
                        <HeaderBut style={{marginRight: `${buttonMargin}px`}} to={'/'}>КАТАЛОГ</HeaderBut>
                        <HeaderBut style={{marginRight: `${buttonMargin}px`}} to={'/faq'}>FAQ</HeaderBut>
                    </div>
                }
                {windowWidth >= 750
                    ?<div className="logo_part">KODR</div>
                    :<></>
                }
            </header>
        )
    }
}

export default Header;