import React, { useEffect, useState } from "react";
import classes from './LikeBut.module.css';
import { axiosAsk, getCookie } from "../../../api/api";
import { WarningAlert } from "../Alert/Alert";

const LikeBut = ({setError, prodId, dbData, ...props}) => {
    const[checked, setChecked] = useState(false);
    const[alertText, setAlertText] = useState('');

    function change(){
        if(getCookie('enter') !== 'login'){
            setAlertText('Пожалуйта, войдите в аккаунт')
            return
        }

        if(checked){
            axiosAsk({
                oper:'remove_tov_from_likes',
                article:prodId,
                chat_id:getCookie('user_id'),
                flag:true
            })
            .then(response => {if(response.data['status'] === 'ok'){setChecked(false)}})
            .catch(response => {console.log(response); setError(true)})
        }else{
            axiosAsk({
                oper:'add_tov_in_likes',
                article:prodId,
                chat_id:getCookie('user_id'),
                flag:true
            })
            .then(response => {if(response.data['status'] === 'ok'){setChecked(true)}})
            .catch(response => {console.log(response); setError(true)})
        }
    }

    useEffect(() => {
        if(dbData === true){
            setChecked(true)
        }
    }, [dbData])
    
    if(checked){
        return(
            <>
                <WarningAlert setNull={setAlertText}>{alertText}</WarningAlert>
                <div onClick={() => change()} {...props} className={classes.like_but_checked}></div>
            </>
        )
    }else{
        return(
            <>
                <WarningAlert setNull={setAlertText}>{alertText}</WarningAlert>
                <div onClick={() => change()} {...props} className={classes.like_but}></div>
            </>
        )
    }
}

export default LikeBut;