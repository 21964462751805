import React, { useEffect } from "react";

function ErrorPage(){
    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
    }, [])

    return(
        <div className="err_page">
            <div className="smile"></div>
            <h1>УПС! Что-то пошло не так!</h1>
            <div className="final_text">Попробуйте перезагрузить сайт или повторите попытку позже. </div>
        </div>
    )
}

export default ErrorPage;