import React, { useEffect } from "react";
import '../../css/Pages/FavoritesPage.css'
import { useState } from "react";
import { axiosAsk, getCookie } from "../../api/api";
import { useNavigate } from "react-router-dom";

import AccHeader from "../Parts/AccHeader";
import Catalog from "../Parts/Catalog";
import ErrorPage from "./ErrorPage";

function FavoritesPage(){
    const[error, setError] = useState(false);
    const[productIds, setProductIds] = useState([]);
    const[products, setProducts] = useState([]);
    const[catalogPos, setCatalogPos] = useState(null);
    const[wait, setWait] = useState(false);

    const navigate = useNavigate()

    function requestFavorites(){
        setWait(true)
        axiosAsk({
            oper:'get_likes',
            flag:true,
            chat_id:getCookie('user_id')
        })
        .then(response => {
            setProductIds(response.data['items'])
            setWait(false)
        })
        .catch(response => {console.log(response); setError(true)})
    }

    useEffect(() => {
        if(getCookie('enter') != 'login'){
            navigate('/login')
        }
        requestFavorites();
        document.getElementById('scroll_wrapper').scrollTo({top: 0, behavior : "smooth"});
    }, [])



    if(error){
        return(
            <ErrorPage/>
        )
    }

    return(
        <div className="favorite_page">
            <AccHeader backPermit={true}>ИЗБРАННОЕ</AccHeader>
            {productIds.length != 0 && !wait
                ?<div className="product_part"><Catalog
                    prodsIds={productIds}
                    prods={products}
                    catalogPos={catalogPos}
                    setError={setError}
                    setProds={setProducts}
                    setCatalogPos={setCatalogPos}
                    setWait={setWait}
                /></div>
                :<div className="empty_favorite">Ваше избранное пусто</div>
            }
            {wait
                ?<div className="wait_icon"></div>
                :<></>
            }
        </div>
    )
}

export default FavoritesPage;